//import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Email from "./components/PageEmail";
import InstructionsModal from "./components/ModalInstructions";
import CloseModal from "./components/ModalClose";
import PreviewModal from "./components/ModalPreview";
import { initReactI18next } from "react-i18next";
import Cache from "i18next-localstorage-cache";
import Backend from "i18next-http-backend";
import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Logo } from "./images/logo.svg";
import { ReactComponent as IconGame } from "./images/icon-game.svg";
import { ReactComponent as IconArrow } from "./images/icon-arrow-white.svg";
import { ReactComponent as IconQuestion } from "./images/icon-question.svg";
import { ReactComponent as IconHome } from "./images/icon-home.svg";
import { ReactComponent as IconCheck } from "./images/icon-check.svg";
import { ReactComponent as IconCamera } from "./images/icon-camera.svg";
import { ReactComponent as IconClose } from "./images/icon-close.svg";
import { ReactComponent as IconLoading } from "./images/icon-loading.svg";
import { ReactComponent as FlagEst } from "./images/est.svg";
import { ReactComponent as FlagEng } from "./images/eng.svg";
import { ReactComponent as FlagRus } from "./images/rus.svg";

i18n
  .use(Cache)
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "et",
    fallbackLng: "et",
    preload: ["et", "en", "ru"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}.json",
    },
    cache: {
      enabled: true,
      prefix: "i18next_res_",
      expirationTime: 60 * 60 * 1000,
    },
  });

function App() {
  const [photos, setPhotos] = useState([]);
  const [data, setData] = useState(null);
  const [player1, setPlayer1] = useState([]);
  const [player2, setPlayer2] = useState([]);
  const removePhoto = (index) => {
    const newPhotos = [...photos];
    if (Array.isArray(index)) {
      const sortedIndex = index.sort().reverse();
      sortedIndex.forEach(index => {
        newPhotos.splice(index, 1);
      });
    } else {    
      newPhotos.splice(index, 1);
    }
    setPhotos(newPhotos);
  };

  const resetData = () => {
    setPhotos([]);
    setPlayer1(data.effects[0]);
    setPlayer2(data.effects[0]);
  };

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/data.json")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setPlayer1(data.effects[0]);
        setPlayer2(data.effects[0]);
      });
  }, []);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className="App">
        <Game
          removePhoto={removePhoto}
          data={data}
          photos={photos}
          setPhotos={setPhotos}
          player1={player1}
          setPlayer1={setPlayer1}
          player2={player2}
          setPlayer2={setPlayer2}
        />
        <Routes>
          <Route path="/email" element={<Email photos={photos} />} />
          <Route path="/" element={<Home resetData={resetData} />} />
          <Route path="instructions" element={<InstructionsModal />} />
          <Route path="close" element={<CloseModal />} />
          <Route
            path="preview"
            element={<PreviewModal photos={photos} removePhoto={removePhoto} />}
          />
          <Route path="game" element={<div></div>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

function Game({
  data,
  photos,
  setPhotos,
  removePhoto,
  player1,
  player2,
  setPlayer1,
  setPlayer2,
}) {
  let navigate = useNavigate();
  let location = useLocation();

  // Data
  const { t } = useTranslation();

  // State
  const [deepAR, setDeepAR] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipElement = useRef(null);
  const canvasEl = useRef(null);
  const [cameraState, setCameraState] = useState("live");
  const [photoSeconds, setPhotoSeconds] = useState(-1);
  const isGameView = location.pathname == "/game";
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  // Methods
  const selectEffect = (playerId, effect) => {
    if (!deepAR) {
      return;
    }
    setIsLoadingFilter(true);
    deepAR.switchEffect(
      parseInt(playerId - 1),
      "face" + (playerId - 1),
      process.env.PUBLIC_URL + "/effects/" + effect.url,
      function(){
        setIsLoadingFilter(false);
      }
    );
  };

  const addPhoto = function (photo) {
    const newPhotos = [...photos];
    newPhotos.push(photo);
    setPhotos(newPhotos);
    if (newPhotos.length == 3) {
      navigate("/preview", { replace: true });
    }
    if (newPhotos.length == 1) {
      setTooltipVisible(true);
    }
    setCameraState("live");
  };

  const takeScreenshot = () => {
    if (cameraState !== "live") {
      return;
    }
    // deepAR.onScreenshotTaken = (photo) => {
    //  addPhoto(photo);
    // };
    setCameraState("photo");
    setPhotoSeconds(3);
  };

  useEffect(() => {
    selectEffect(1, player1);
  }, [player1]);

  useEffect(() => {
    selectEffect(2, player2);
  }, [player2]);

  useEffect(() => {
    if (!deepAR) {
      return;
    }
    deepAR.onScreenshotTaken = (photo) => {
      addPhoto(photo);
    };
  }, [deepAR, photos]);

  useEffect(() => {
    let timer = null;

    if (tooltipVisible) {
      tooltipElement.current.classList.add("is-visible");
      timer = setTimeout(function () {
        tooltipElement?.current?.classList.remove("is-visible");
        setTooltipVisible(false);
      }, 3000);
    }
  }, [tooltipVisible]);

  useEffect(() => {
    if (photoSeconds > 0) {
      setTimeout(() => {
        setPhotoSeconds(photoSeconds - 1);
      }, 1000);
    }
    if (photoSeconds === 0) {
      setCameraState("take");
      setTimeout(() => {
        deepAR.takeScreenshot();
      }, 1000);
      setPhotoSeconds(-1);
    }
  }, [photoSeconds]);

  useEffect(() => {
    setIsLoadingFilter(true);
    const deepArObj = window.DeepAR({
      canvasWidth: canvasEl.current.scrollWidth,
      canvasHeight: canvasEl.current.scrollHeight,
      licenseKey:
        "ee8a5c9dbc130a0ec01d7cc80950a1d1746f8e8b2de40005972abec8f93585e659cc09e3278e87d7",
      canvas: canvasEl.current,
      numberOfFaces: 2,
      libPath: "./lib",
      segmentationInfoZip: "segmentation.zip",
      onInitialize: function () {
        deepArObj.startVideo(true);
        deepArObj.switchEffect(
          0,
          "face0",
          process.env.PUBLIC_URL + "/effects/" + data.effects[0].url,
          function () {
            setIsLoadingFilter(false);
          }
        );
        deepArObj.switchEffect(
          1,
          "face0",
          process.env.PUBLIC_URL + "/effects/" + data.effects[0].url,
          function () {
            // effect loaded
          }
        );
      },
    });
    deepArObj.downloadFaceTrackingModel(
      process.env.PUBLIC_URL + "/lib/models-68-extreme.bin"
    );
    setDeepAR(deepArObj);

    const updateCanvasSize = () => {
      deepArObj.setCanvasSize(
        canvasEl.current.scrollWidth,
        canvasEl.current.scrollHeight
      );
    };

    window.addEventListener("resize", updateCanvasSize);

    return function cleanup() {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  return (
    <div className="game">
      <div className="game-left">
        <canvas ref={canvasEl} className="game-canvas"></canvas>
        <div className={`game-filter-loading ${isLoadingFilter && `is-visible`}`}>
          <div>
            {t("Laen filtrit")}
            <span>
              <IconLoading />
            </span>
          </div>
        </div>
        <div
          className={`game-photo-overlay ${
            cameraState !== "take" && `is-hidden`
          }`}
        ></div>
        <div ref={tooltipElement} class="tooltip game-images-instructions" dangerouslySetInnerHTML={{__html:t("Kui sulle pilt ei meeldi, klõpsa kustutamiseks %icon%").replace('%icon%', '<span class="game-images-instructions-icon"><svg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.39 4 12 10.39 5.61 4 4 5.61 10.39 12 4 18.39 5.61 20 12 13.61 18.39 20 20 18.39 13.61 12 20 5.61 18.39 4Z" fill="currentColor"/></svg></span>')}}>

        </div>
        <ul className="game-images">
          <li>
            {photos.length > 0 && (
              <button
                className="game-image-delete"
                onClick={() => removePhoto(0)}
              >
                <IconClose width="24" height="24" />
              </button>
            )}
            <div className="game-images-image">
              {photos.length < 1 && <IconCamera width="26" height="23" />}
              {photos.length > 0 && <img src={photos[0]} />}
            </div>
          </li>
          <li>
            {photos.length > 1 && (
              <button
                className="game-image-delete"
                onClick={() => removePhoto(1)}
              >
                <IconClose width="24" height="24" />
              </button>
            )}
            <div className="game-images-image">
              {photos.length < 2 && <IconCamera width="26" height="23" />}
              {photos.length > 1 && <img src={photos[1]} />}
            </div>
          </li>
          <li>
            {photos.length > 2 && (
              <button
                className="game-image-delete"
                onClick={() => removePhoto(2)}
              >
                <IconClose width="24" height="24" />
              </button>
            )}
            <div className="game-images-image">
              {photos.length < 3 && <IconCamera width="26" height="23" />}
              {photos.length > 2 && <img src={photos[2]} />}
            </div>
          </li>
        </ul>
        <div className="button-camera" onClick={() => takeScreenshot()}>
          {cameraState === "photo" && (
            <svg height="178" width="178" className="button-camera-circle">
              <circle
                cx="89"
                cy="89"
                r="84"
                stroke="#F15A2A"
                strokeWidth="11"
                fill="#dadfe8"
                strokeDasharray={527}
                strokeDashoffset={photoSeconds * 105 + 2}
              />
            </svg>
          )}
          <div className="button-camera-inner">
            {cameraState === "live" && (
              <div className="button-camera-camera">
                <IconCamera width="26" height="23" />
              </div>
            )}
            {cameraState === "photo" && (
              <div className="button-camera-counter">{photoSeconds}</div>
            )}
            {cameraState === "take" && (
              <div className="button-camera-take"></div>
            )}
          </div>
          <div className="button-camera-inner-shadow"></div>
        </div>
      </div>
      <div className="game-right">
        <div className="game-filters-header">
          <Logo width="119" />
          <Link
            className={`button-help ${!isGameView && `disabled`}`}
            to="/instructions"
          >
            <IconQuestion width="24" height="24" />
          </Link>
          <Link
            className={`button-home ${!isGameView && `disabled`}`}
            to="/close"
          >
            <IconHome width="24" height="24" />
          </Link>
        </div>
        <div className={`game-filters ${!isGameView && `disabled`}`}>
          <div className="game-filters-player">
            <div className="game-selected-filter">
              <div className="game-selected-filter-image-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/" + player1.thumbnail}
                />
              </div>
              {t("Mängija 1")}
            </div>

            <ul className="game-filters-1">
              {data.effects.map((effect, index) => (
                <li key={index}>
                  <button
                    className={`button-filter ${
                      effect.url === player1.url && "selected"
                    }`}
                    onClick={() => setPlayer1(effect)}
                  >
                    <span className="button-filter-sex">{t(effect.sex)}</span>
                    <span className="button-filter-check">
                      <IconCheck width="13" height="10" />
                    </span>
                    <span className="button-filter-image">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/" + effect.thumbnail
                        }
                      />
                    </span>
                    {t(effect.name)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="game-filters-player">
            <div className="game-selected-filter">
              <div className="game-selected-filter-image-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/" + player2.thumbnail}
                />
              </div>
              {t("Mängija 2")}
            </div>
            <ul className="game-filters-2">
              {data.effects.map((effect, index) => (
                <li key={index}>
                  <button
                    className={`button-filter ${
                      effect.url === player2.url && "selected"
                    }`}
                    onClick={() => setPlayer2(effect)}
                  >
                    <span className="button-filter-sex">{t(effect.sex)}</span>
                    <span className="button-filter-check">
                      <IconCheck width="13" height="10" />
                    </span>
                    <span className="button-filter-image">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/" + effect.thumbnail
                        }
                      />
                    </span>
                    {t(effect.name)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function Home({ resetData }) {
  let navigate = useNavigate();
  const [logoClicks, setLogoClicks] = useState(0);

  useEffect(() => {
    resetData();
  }, []);
  return (
    <div className="home overlay">
      <div className="home-left bg-gray-300">
        <IconGame height="400" />
      </div>
      <div className="home-right bg-white shadow">
        <Logo className="home-logo" onClick={() => {
          if (logoClicks == 10) {
            window.location.reload(false);
          }
          setLogoClicks(logoClicks + 1);
        }} width="230" />
        <button
          className="button-start"
          onClick={(e) => {
            e.preventDefault();
            i18n.changeLanguage("et").then((t) => {});
            navigate("/instructions", { replace: true });
          }}
        >
          <span className="button-start-flag">
            <FlagEst width="64" height="64" />
          </span>
          <span className="button-start-text">
            <span className="button-start-title">Edevuse mäng</span>
            Juuksemood muutub läbi aja. Proovi pähe eri ajastute soenguid.
          </span>
          <span className="button-start-arrow">
            <IconArrow width="40" />
          </span>
        </button>
        <button
          className="button-start"
          onClick={(e) => {
            e.preventDefault();
            i18n.changeLanguage("en").then((t) => {});
            navigate("/instructions", { replace: true });
          }}
        >
          <span className="button-start-flag">
            <FlagEng width="64" height="64" />
          </span>
          <span className="button-start-text">
            <span className="button-start-title">The Vanity Game</span>
            Hair trends change over time. Try hairstyles from different eras.
          </span>
          <span className="button-start-arrow">
            <IconArrow width="40" />
          </span>
        </button>
        <button
          className="button-start"
          onClick={(e) => {
            e.preventDefault();
            i18n.changeLanguage("ru").then((t) => {});
            navigate("/instructions", { replace: true });
          }}
        >
          <span className="button-start-flag">
            <FlagRus width="64" height="64" />
          </span>
          <span className="button-start-text">
            <span className="button-start-title">Найди свой образ</span>
            Mода на причёски со временем меняется. Попробуйте прически разных
            эпох.
          </span>
          <span className="button-start-arrow">
            <IconArrow width="40" />
          </span>
        </button>
      </div>
    </div>
  );
}

export default App;
